import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import logo from '../images/logo_consult.png'
import InterestForm from '../components/InterestForm'
import { GatsbySeo } from 'gatsby-plugin-next-seo'


export const query = graphql`
  query {
    hero: file(relativePath: { eq: "tower_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({data}) => {

  return (
  <>
    <GatsbySeo title='PanSurg CONSULT'
          description='Consequences on Surgical Training by the COVID-19 pandemic (CONSULT).'/>
      <Hero title="PanSurg CONSULT"
            subtitle="Consequences on Surgical Training by the COVID-19 pandemic (CONSULT)."
            fluid={data.hero.childImageSharp.fluid}
            buttonTxt = "Participate"
            buttonLink= "https://www.surveymonkey.co.uk/r/consult19"
            breadcrumbs
        secondcrumb = 'Research'
        secondlink = '/research'
        activecrumb = 'CONSULT'
        />

  <section className="section is-small" style={{marginTop:'0rem'}}>
    <div className="container">
      <div className="is-thin">

       <div className="has-text-centered">
        <img src={logo} width="400px" alt="COMS-UK Logo" style={{marginBottom:'2rem'}}/>
  </div>


      <div className="content">

      <p>The CONSULT-19 survey has been developed by trainees for trainees, to understand the impact COVID will have on our career progression.</p>
              <p>This short survey allows us to better evaluate the loss of training through redeployment and cancellation of elective lists. Our findings will be shared with all relevant training bodies to help define training in the coming months.</p>
              <div className="buttons is-centered">
                <a className="button is-rounded is-red" href="https://www.surveymonkey.co.uk/r/consult19" target="_blank">Complete the survey</a>
                </div>
      </div>
    </div>
    </div>
  </section>



  </>
  )
}
